<template>
  <div>
    <transition-group
      name="list"
      tag="ul"
      class="chart-list"
      v-if="chartData.length > 0"
    >
      <li v-for="item in limitedChartData" :key="item.participant" class="list-item">
        <div class="participant-name">{{ item.participant }}</div>
        <div class="bar-container" :style="{ width: containerWidth }">
          <div class="bar" :style="{ width: barWidth(item.votes) }">
            <transition name="fade">
              <span v-if="showPercentage">{{ percentage(item.votes) }}</span>
            </transition>
          </div>
          <!-- Neue Bedingung für die Anzeige der Gesamtstimmen hinter dem Balken -->
          <div v-if="showTotalVotes" class="votes-count">{{ item.votes }}</div>
        </div>
      </li>
      <!-- Neue Bedingung für die Anzeige der Gesamtstimmen -->
      <li v-if="showTotalVotes" key="total-votes" class="list-item total-votes">
        <div class="participant-name">Gesamtstimmen</div>
        <div>{{ totalVotes }}</div>
      </li>
    </transition-group>
    <div v-else>Daten werden geladen...</div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  onUnmounted,
} from "vue";

export default defineComponent({
  name: "Analytics",
  setup() {
    const chartData = ref([]);
    const showPercentage = ref(true);
    const limit = ref(null); // Neuer Ref für den Limit-Parameter
    const showTotalVotes = ref(false); // Neuer Ref für die Anzeige der Gesamtstimmen

    // Funktion zum Lesen der Query-Parameter 'limit' und 'totalvotes'
    function readLimitFromURL() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      limit.value = params.limit ? parseInt(params.limit, 10) : null; // Setzt limit.value auf den Wert des Query-Parameters oder null, falls nicht vorhanden
      showTotalVotes.value = params.totalvotes === "1"; // Setzt showTotalVotes basierend auf dem Query-Parameter
    }

    async function fetchChartData() {
      try {
        const response = await fetch(
          "https://lbdmnp24-secureserver.mainly-dev.de:3000/evaluations"
        );
        const data = await response.json();

        const newDataArray = Object.entries(data).map(
          ([participant, votes]) => ({
            participant,
            votes,
          })
        );

        newDataArray.sort((a, b) => b.votes - a.votes);

        // Erstellen Sie ein neues Array mit neuen Objekten für Vue, um Änderungen zu erkennen
        const updatedChartData = newDataArray.map((item) => ({ ...item }));

        chartData.value = updatedChartData;
      } catch (error) {
        console.error("Fehler beim Abrufen der Daten:", error);
      }
    }

    let intervalId;

    onMounted(() => {
      readLimitFromURL();
      fetchChartData();
      intervalId = setInterval(fetchChartData, 3000);
      const fontLink = document.createElement("link");
      fontLink.href = "https://use.typekit.net/ehq2qvb.css";
      fontLink.rel = "stylesheet";
      document.head.appendChild(fontLink);

      onUnmounted(() => {
        document.head.removeChild(fontLink);
        clearInterval(intervalId);
      });
    });

    onUnmounted(() => {
      clearInterval(intervalId);
    });

    const totalVotes = computed(() => {
      return chartData.value.reduce((total, item) => total + item.votes, 0);
    });

    const limitedChartData = computed(() => {
      return limit.value ? chartData.value.slice(0, limit.value) : chartData.value;
    });

    function barWidth(votes) {
      if (totalVotes.value === 0) return "0%";
      const width = (votes / totalVotes.value) * 100;
      return width + "%";
    }

    function percentage(votes) {
      if (totalVotes.value === 0) return "0%";
      const percent = (votes / totalVotes.value) * 100;
      return percent.toFixed(2) + "%";
    }

    const maxVotes = computed(() => {
      return Math.max(...chartData.value.map((item) => item.votes), 0); // Sicherstellen, dass ein Fallback auf 0 besteht
    });

    const containerWidth = computed(() => {
      const maxPercentage =
        maxVotes.value > 0 ? (maxVotes.value / maxVotes.value) * 100 : 0;
      return `${maxPercentage}%`; // Setzt die maximale Breite relativ zum Teilnehmer mit den meisten Stimmen
    });

    return {
      limitedChartData,
      chartData,
      barWidth,
      percentage,
      showPercentage,
      containerWidth, // Fügen Sie dies zu den zurückgegebenen Eigenschaften hinzu
      showTotalVotes, // Fügen Sie dies zu den zurückgegebenen Eigenschaften hinzu
      totalVotes, // Fügen Sie dies zu den zurückgegebenen Eigenschaften hinzu
    };
  },
});
</script>

<style>
.chart-list {
  list-style-type: none;
  padding-left: 0;
}

.list-item {
  margin-bottom: 10px;
  display: flex; /* Verwendet Flexbox für das Layout */
  align-items: center; /* Zentriert die Elemente vertikal */
}

.participant-name {
  flex: 0 0 200px; /* Weist dem Teilnehmernamen eine feste Breite zu und verhindert das Wachsen oder Schrumpfen */
  margin-right: 1rem; /* Erhöht den Abstand zwischen Teilnehmernamen und Balken */
  color: white;
  font-family: mr-eaves-xl-sans-narrow, sans-serif;
}

.bar-container {
  display: inline-block; /* Macht den Container inline */
  margin-left: 10px; /* Fügt Abstand zwischen Namen/Stimmen und dem Balken hinzu */
  position: relative; /* Ermöglicht die Positionierung des Stimmenzählers */
}

.bar {
  background-image: linear-gradient(
    to right,
    #e62154,
    #ef7d17
  ); /* Farbverlauf von #e62154 zu #ef7d17 */
  color: white; /* Übernimmt die Schriftfarbe vom übergeordneten Element */
  padding: 2px 5px;
  transition: width 0.5s ease;
  font-family: mr-eaves-xl-sans-narrow, sans-serif;
}

.votes-count {
  position: absolute; /* Positioniert den Stimmenzähler absolut innerhalb des Containers */
  right: 50px; /* Verschiebt den Stimmenzähler nach rechts */
  top: 0; /* Vertikale Ausrichtung */
  color: white;
  font-family: mr-eaves-xl-sans-narrow, sans-serif;
  font-size: 14px;
}

.total-votes {
  color: white;
  font-family: mr-eaves-xl-sans-narrow, sans-serif;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
