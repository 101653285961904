<template>
  <div id="app">
    <Analytics /> <!-- Die Analytics Komponente wird hier eingebettet -->
  </div>
</template>

<script>
import Analytics from './components/Analytics.vue'; // Stellen Sie sicher, dass der Pfad korrekt ist

export default {
  name: 'App',
  components: {
    Analytics // Registrieren Sie die Analytics Komponente
  }
};
</script>
